<template>
  <div class="container">
    <h1>Test Editor</h1>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig">
    </ckeditor>
    <v-btn @click="showEditorData"> fetch </v-btn>
    <!--<textarea id="editor"> </textarea> -->
  </div>
</template>
<script>
// import CKEditor from "@ckeditor/ckeditor5-vue2";
// import ClassicEditor from "../../ckeditor-build/build/ckeditor";
// import simpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Editor from "../../ckeditor-build/build/ckeditor";
// import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
export default {
  name: "TestEditor",
  methods: {
    showEditorData() {
      console.log(this.getApiUrl);
    },
  },
  data() {
    return {
      editor: Editor,
      editorData: "",
      editorConfig: {
        simpleUpload: {
          uploadUrl: process.env.VUE_APP_API_URL + "/upload-ckeditor-image",
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImIwZDUyMDQ5NzA3YWM4MmMxNTFlMjhjOWYyODVjMzZiOWY4NDg0Y2Q2MDVkNjFlZTM0ZWVkMzYwMGUyNDk5NTU5YTc3OWFiNzczMGY1MjAwIn0.eyJhdWQiOiIxIiwianRpIjoiYjBkNTIwNDk3MDdhYzgyYzE1MWUyOGM5ZjI4NWMzNmI5Zjg0ODRjZDYwNWQ2MWVlMzRlZWQzNjAwZTI0OTk1NTlhNzc5YWI3NzMwZjUyMDAiLCJpYXQiOjE2ODEyMDg1NzAsIm5iZiI6MTY4MTIwODU3MCwiZXhwIjoxNjgyNDE4MTcwLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.iKKZr4VyPPL-p_2eMJeYt4pCBF-a6vwll38VonW9P97BkeDjl6cBm1K4h3YcQ5dyBKu1FnRaOxf6rK-vPAD6BrUKhXLATV14gjeHM-Pxy1aY1XTRJep-W1tNP2eNDjQWW8xRed1nkT5VHvpSQfKYju_dqj2VpjjNOvbK3GFHlvf4XOHX_1wK64wBjUbgrpGNTmdzkWBuE8IXb9E5RwRpOMSZaIywsws2gwCPk9MeED65B_8qFPaTrKWCl0K-G4CoSAUyIUzaaNGlwUhMrlUZHl2bKflJLINdMsj8RjKavLOLGAZpJLjUWkEJGf9-jEXV0MWtGBir0Et8G90UFFTlzFguRjiVIaIbQ_O-sRw-cgE8xi1_HgskDVzOQ9zZwWoWaTBN0rqQzK2sD_d8eJuPlpTlraM6yUSr9KnOLkcypUtsHQt5A2lPn8DpYdCwLHTVtub2UHBF1rl3ft-TwWrbO-tw6Kgl-cTYSw90zA09gUofrklB1fNn7_og1crcxNkmLPpGXIkkao3u-CM87_H--8HSltinvWjHP3dTcSJgRXTA8JjmTqVH-bEnH-q5twkkwVCTpDTlsY9uTFk6XnJ2aXOtcF-ZMxSe2bfuyIwecJc-OfAdAMNpaJUHqmJR7Xg4YY4rlYpC4JtzFsfqLlSD9KJWKA7y1xXPe4bL58Vc_JQ",
            enctype: "multipart/form-data",
          },
        },
      },
    };
  },
  mounted() {},
};
</script>
